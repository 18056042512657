// import external dependencies
import "jquery";

// import 'masonry-layout/dist/masonry.pkgd.min';

import "./vendor/responsive-menu.js";
import "./vendor/jssocials.custom.js";
import "./site-header.js";
// import then needed Font Awesome functionality
import { library, dom } from "@fortawesome/fontawesome-svg-core";
// import the Facebook and Twitter icons
import {
  faFacebookF,
  faTwitter,
  faPinterestP,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import "./animation.js";
import barbaInit from "./barba-init.js";
// Import everything from autoload
import "./autoload/_bootstrap.js";

// import local dependencies
import Router from "./util/Router";
import common from "./routes/common";
import home from "./routes/home";
import aboutUs from "./routes/about";

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,

  // Home page
  home,

  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
});

// add the imported icons to the library
library.add(faFacebookF, faTwitter, faPinterestP, faLinkedinIn);

// tell FontAwesome to watch the DOM and add the SVGs when it detects icon markup
dom.watch();

// Load Events
jQuery(document).ready(() => {
  routes.loadEvents();
  barbaInit(routes);
});
