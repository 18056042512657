jQuery(function ($) {
  var lastScrollTop = 0;
  var scrollTop;

  // Add shrink class to site header after 50px

  $(document).on("scroll", function () {
    scrollTop = $(window).scrollTop();

    if (scrollTop > lastScrollTop && scrollTop > 500) {
      $(".site-header").addClass("hidden");
    } else {
      $(".site-header").removeClass("hidden");
    }
    if (
      scrollTop > 500 &&
      scrollTop < $(document).height() - $(window).height() - 250
    ) {
      $(".cta-bar").addClass("visible");
    } else {
      $(".cta-bar").removeClass("visible");
    }
    if (scrollTop > 0) {
      $(".home .site-header").addClass("bg-white shrink");
      $(".site-header.big").removeClass("big");
    } else {
      $(".home .site-header").removeClass("bg-white");
      $(".home .site-header").addClass("big");
    }
    lastScrollTop = scrollTop;
  });
});
