import {
  TimelineLite,
  Power3,
  // CSSPlugin,
  // ScrollToPlugin
} from "gsap";
// import { TweenLite } from "TweenLite";
// import { TimelineLite } from "TimelineLite";
// import { Power3 } from "EasePack";
//import CSSRulePlugin from "CSSRulePlugin";
import ScrollMagic from "ScrollMagic";
import SplitText from "./vendor/SplitText.min.js";
// import "animation.gsap";
var controllr;

$(".side-fixed").css("left", "0px");
setTimeout(function () {
  $(".side-fixed").css("left", "");
}, 3500);
function initScrollAnimations() {
  var controllr = new ScrollMagic.Controller();

  $(".scroll-reveal").each(function () {
    var element = $(this);

    var scene = new ScrollMagic.Scene({
      triggerHook: 0.75,
      triggerElement: element.get(0),
      reverse: false,
    })
      .on("start", function (e) {
        if ("REVERSE" != e.scrollDirection) {
          element.trigger("reveal");

          scene.remove();
        }
      })

      // .addIndicators();
      .addTo(controllr);
    //element.css({ opacity: 0 });
  });
  return controllr;
}

function resetScrollTriggers() {
  $(".scroll-reveal").on("reveal", scrollRevealHandler);
}

// function loadImageMasks() {
//   $(".image-container")
//     .css({ opacity: 0 })
//     .append('<div class="mask"></div>');
// }

export default function loadPageAnimations() {
  // loadImageMasks();
  controllr = controllr.destroy(true);
  controllr = initScrollAnimations();
  resetScrollTriggers();
}

function scrollRevealHandler() {
  var tl = new TimelineLite();
  var element = $(this);
  var articles = element.find("article, .hero-accueil-cta, blockquote");

  var titres = element
    .find(
      "p.sstitle, .titre, .baseline, h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6"
    )
    .not(".post-list .entry-title");
  var images = element
    .find("img:not(.thumbnail-image)")
    .not(".hero-banner img");
  var btn = element.find(".btn, .button, button").not(".post-list .btn");
  //var p = element.find('p:not("li, .split-line, .entry-meta")');
  var p = element.find("p, li, cite, .page-excerpt").not(".post-list p");

  var lineDelay = 0.1;
  //var blocs = element.find('article');

  //var formes = element.find('.hline');
  //var anims = element.find('.anim-container');

  // btn.css({
  //   opacity: 0
  // });

  // for(let a of anims_list){
  //     console.log(anims_list);
  //     if (abovePercent($(a.container), 80)) {
  //         a.anim.play();
  //     }
  // }
  //
  if (0 !== titres.length) {
    new SplitText(titres, {
      // new SplitText(lignes, {
      type: "lines, words",
      linesClass: "line",
      wordsClass: "word",
    });
  }
  var lines = element.find(".line");
  titres.css("visibility", "visible");
  //words.css({ opacity: 0 });

  //element.css({ opacity: "" }).addClass("is-revealed");

  tl.pause();

  // if (element.is(".hero-accueil")) {
  //   tl.delay(1);
  // }

  // if (element.is("article")) {
  //   tl.fromTo(
  //     element,
  //     0.8,
  //     {
  //       opacity: 0,
  //       y: 50
  //     },
  //     {
  //       opacity: 1,
  //       y: 0
  //     }
  //   );
  // }
  //
  tl.staggerFromTo(
    articles,
    0.8,
    {
      autoAlpha: 0,
      y: 50,
    },
    {
      autoAlpha: 1,
      y: 0,
    },
    0.15
  );

  tl.staggerFromTo(
    images,
    0.8,
    {
      autoAlpha: 0,
    },
    {
      autoAlpha: 1,
    },
    0.15
  );

  // tl.call(function() {
  //     anims.each(function() {
  //       var anim = bodymovin.loadAnimation({
  //         container: this, // the dom element
  //         renderer: 'svg',
  //         loop: $(this).attr("data-loop") == "true" ? true : false,
  //         autoplay: true,
  //         path: theme_path + '/js/anim/' + $(this).attr("data-anim") + ".json",
  //       });
  //     });
  //   })

  //   .add(TweenMax.staggerTo(images, 0, { clearProps: "opacity", className: "+=is-visible" }, 0.3));

  lines.each(function (index) {
    var line = $(this);
    var words = line.find(".word");
    tl.fromTo(
      words,
      0.8,
      {
        autoAlpha: 0,
        top: line.height(),
      },
      {
        autoAlpha: 1,
        top: -2,
        ease: Power3.easeOut,
      },
      index * lineDelay
    );
  });

  // tl.staggerFromTo(
  //   lines,
  //   0.8,
  //   {
  //     autoAlpha: 0,
  //     top: $(this).height()
  //   },
  //   {
  //     autoAlpha: 1,
  //     top: -2
  //   }
  // );

  tl.staggerFromTo(
    p,
    0.3,
    {
      autoAlpha: 0,
      y: 15,
    },
    {
      autoAlpha: 1,
      y: 0,
    },
    0.3,
    "-=0.5"
  );

  // tl.add(TweenMax.staggerFromTo(formes, 0.3, {
  //   transform: "scale(0)",
  // }, {
  //   transform: "scale(1)",
  //   ease: Power3.easeOut,
  // }));

  tl.fromTo(
    btn,
    0.5,
    {
      autoAlpha: 0,
    },
    {
      autoAlpha: 1,
      //ease: Power3.easeOut
    },
    "-=0.3"
  );
  // tl.call(function() {
  //   st.revert();
  // });

  tl.play();
  return false;
}

(function ($) {
  //* Make sure JS is enabled
  document.documentElement.className = "js";

  $(document).ready(function () {
    //TweenMax.to($('.overlay'), 1.5,{autoAlpha: 0, display:'none', ease: Power0.easeNone});

    //    $(".overlay").fadeOut();

    // création du masque pour l'apparition des images
    //loadImageMasks();

    controllr = initScrollAnimations();
    resetScrollTriggers();
  });
})(jQuery);
