import autosize from 'autosize/dist/autosize.min.js';
import klaroInit from '../klaro';

//import "../animation.js";

export default {
  init() {
    // JavaScript to be fired on all pages

    // add passive event listener to touch and scroll events as suggested by https://web.dev/
    //document.addEventListener("touchstart", onTouchStart, { passive: true });
    // document.addEventListener("wheel", onTouchStart, { passive: true });
    // document.addEventListener("mousewheel", onTouchStart, { passive: true });
    // document.addEventListener("touchmove", onTouchStart, { passive: true });

    klaroInit();

    $('.wpcf7-submit').on('click', function () {
      $(this).addClass('loading');
      $(this)
        .closest('.wpcf7')
        .find('.wpcf7-response-output')
        .hide(0, function () {
          $(this).removeClass(
            'alert alert-danger alert-warning alert-success mt-2',
          );
        });
    });

    $('.wpcf7').on('wpcf7submit', function () {
      $(this).find('.wpcf7-response-output').fadeIn();
      $(this).find('.wpcf7-submit').removeClass('loading');
    });

    $('.wpcf7').on('wpcf7submit', function () {
      $(this)
        .find('.wpcf7-response-output')
        .not('.alert-warning')
        .not('.alert-danger')
        .addClass('alert alert-success mt-2');
    });

    /* Validation Events for changing response CSS classes */

    document.addEventListener(
      'wpcf7invalid',
      (event) => {
        $(event.target)
          .find('.wpcf7-response-output')
          .not('.alert-warning')
          .not('.alert-success')
          .addClass('alert alert-danger mt-2');
      },
      false,
    );
    document.addEventListener(
      'wpcf7spam',
      (event) => {
        $(event.target)
          .find('.wpcf7-response-output')
          .not('.alert-danger')
          .not('.alert-success')
          .addClass('alert alert-warning mt-2');
      },
      false,
    );
    document.addEventListener(
      'wpcf7mailfailed',
      (event) => {
        $(event.target)
          .find('.wpcf7-response-output')
          .not('.alert-danger')
          .not('.alert-success')
          .addClass('alert alert-warning mt-2');
      },
      false,
    );
    document.addEventListener(
      'wpcf7mailsent',
      (event) => {
        $(event.target)
          .find('.wpcf7-response-output')
          .not('.alert-danger')
          .not('.alert-warning')
          .addClass('alert alert-success mt-2');
      },
      false,
    );
    // minimalist autoresizable textareas
    autosize($('textarea'));
    $('#share').jsSocials({
      showLabel: false,
      showCount: false,
      shares: ['facebook', 'twitter', 'linkedin', 'pinterest'],
    });
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    console.log('_JS_READY'); // eslint-disable-line no-console
  },
};
